import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50439e92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card h-100 border-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card2 = _resolveComponent("Card2")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card border-0", _ctx.widgetClasses]),
    "data-aos": "zoom-in"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Card2, {
        "card-classes": "h-100",
        title: "Total Lora Devices",
        icon: "fa-satellite-dish text-success",
        content: "3156",
        color: "success",
        arrow: "Up-right",
        "footer-data": "+40.5%",
        "footer-text": "This Month"
      })
    ])
  ], 2))
}