
import { defineComponent } from "vue";

export default defineComponent({
  name: "card-2",
  components: {},
  props: {
    cardClasses: String,

    icon: String,

    // iconcolor: String,

    title: String,

    content: String,

    color: String,

    arrow: String,

    footerData: String,

    footerText: String,

    indicatorValue: String,

    indicatorLabel: String,
  },
});
